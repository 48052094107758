export function getDateFormatted(date) {
    let dateObj = new Date(date);
    let formattedDate = `${dateObj.getFullYear()}/${dateObj.getMonth() + 1}/${dateObj.getDate()}`;
    return formattedDate;
}

export function getDateFormattedFirstDay(date) {
    let dateObj = new Date(date);
    let formattedDate = `${dateObj.getFullYear()}/${(dateObj.getMonth() + 1).toString().padStart(2,0)}/${dateObj.getDate().toString().padStart(2,0)}`;
    return formattedDate;
}

/**
 * @param {string} date 
 */
export function dateIsValid(date) {
    const fragments = date.split('/');

    return fragments.every(fragment => !isNaN(Number(fragment))) && fragments.length === 3;
} 

/**
 * @param {number} payDay 
 * @param {number} planAnual 
 * @returns 
 */
export function getNextTerminationDate(payDay, planAnual = false) {
    let thirtyDays = 2592000000;
    let oneYear = 31556926000;
    let terminationDate = payDay;
    let rightNow = Date.now();
    
    while (terminationDate <= rightNow) {
        if(planAnual){
            terminationDate += oneYear;
        }else{
            terminationDate += thirtyDays;
        }
    }

    return terminationDate
}