import React, { Component } from 'react';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import { getAllUsers } from "../../../service/users"
import DataTableComponent from '../core/DataTableComponent'
import { dateIsValid, getDateFormatted, getNextTerminationDate } from '../../../service/functions';
import ExcelJS from 'exceljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

class UserComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            showOnlyPremiumManual: false
        };
    }

    componentDidMount() {
        this.getInitialDate()
    }
    getInitialDate = async () => {
        this.context.iniciaCarga()
        let users = await getAllUsers()
        this.setState({ users })
        this.context.terminaCarga()
    }
    getColumns = () => [
        { label: 'Nombre', field: 'name', sort: 'asc' },
        { label: 'Correo electrónico', field: 'email', sort: 'asc' },
        { label: 'Membresía', field: 'type', sort: 'asc' },
        { label: 'Fecha de registro', field: 'date', sort: 'asc' },
    ];
    getData = () => {
        const { users, showOnlyPremiumManual } = this.state
        let rows = users.map(user => ({
            ...user,
            type: this.getFormattedUserType(user),
            date: getDateFormatted(user.date),
            clickEvent: (ev) => this.props.history.push(`/admin/Users/User/${user.id}`)
        }));

        if (showOnlyPremiumManual)//Filtrar solo premium manual cuando seleccionan el checkbox
            rows = rows.filter(user => user.subscriptionId === "MANUAL")

        return { columns: this.getColumns(), rows };
    }

    downloadReport = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Usuarios');
        sheet.columns = [
            { header: 'Nombre', key: 'name', width: 32 },
            { header: 'Correo electrónico', key: 'email', width: 32 },
            { header: 'Membresía', key: 'type', width: 32 },
            { header: 'Ciudad', key: 'city', width: 32 },
            { header: 'País', key: 'country', width: 32 },
            { header: 'Fecha de registro', key: 'date', width: 32 },
            { header: 'Fecha de renovación de suscripción', key: 'terminationDate', width: 32 },
        ];

        this.state.users.sort((a, b) => {
            // sort by type
            if (this.userIsPremiumAnual(a) && !this.userIsPremiumAnual(b)) return -1;
            if (!this.userIsPremiumAnual(a) && this.userIsPremiumAnual(b)) return 1;
            if (a.type === "Premium" && b.type !== "Premium") return -1;
            if (a.type !== "Premium" && b.type === "Premium") return 1;

            // otherwise sort alphabetically
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            return 0;
        }).forEach(user => {
            if (user.type === "Admin") return;

            const terminationDate = user.paymentDay && user.type === "Premium"
                ? getDateFormatted(getNextTerminationDate(Number(user.paymentDay))) 
                : "";
            const date = user.date ? getDateFormatted(Number(user.date)) : "";

            sheet.addRow({
                name: user.name,
                email: user.email,
                type: this.getFormattedUserType(user),
                city: user.city,
                country: user.country,
                date: dateIsValid(date) ? date : "N/A",
                terminationDate: dateIsValid(terminationDate) ? terminationDate : "N/A",
            });
        });

        workbook.xlsx.writeBuffer().then((data) => {
            const date = getDateFormatted(new Date()).replace(/\//g, "-");
            console.log(date, `Usuarios-${date}.xlsx`)
            
            data = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `Usuarios-${date}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }); 
    }

    getFormattedUserType = (user) => {
        return this.userIsPremiumAnual(user)
            ? `${user.type} (Anual)` 
            : user.type;
    }

    userIsPremiumAnual = (user) => user.hasPremiumAnual && user.type === "Premium";

    render() {
        const { showOnlyPremiumManual } = this.state
        const checkPremiumManual = () => this.setState({ showOnlyPremiumManual: !showOnlyPremiumManual })
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Usuarios</h1>
                        <div className="user-table-header">
                            <div>
                                <div className="form-check form-check-inline">
                                    <input name="showOnlyPremiumManual" checked={showOnlyPremiumManual} className="form-check-input" onChange={() => this.setState({ showOnlyPremiumManual: !showOnlyPremiumManual })} type="checkbox" />
                                </div>
                                <label className="m-0">Mostrar solo usuarios con premium manual</label>
                            </div>

                            <button type="button" className="btn btn-primary" onClick={() => this.downloadReport()}>
                                <FontAwesomeIcon icon={faDownload} style={{ marginRight: "10px"}} />
                                Descargar usuarios
                            </button>
                        </div>

                        <DataTableComponent data={this.getData()} />

                        <div className="btn-row"  >
                            <button type="button" className="btn btn-primary" onClick={() => this.props.history.push("/admin/Users/User")}>Crear nuevo usuario</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
UserComponent.contextType = CurrentUser;
export default UserComponent;